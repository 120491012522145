export const banner = {
    bgimg: {
        backgroundImage: `url(${require('../images/banner-bg.jpg')})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    bannertext: "Reach out for Cybersecurity Services",
    bannertitle: "Secure Your Company Today",
    bannerdetail: "We at S3 Infosoft provide customer-centric solutions for resolving Software Product development bottlenecks. We offer Cybersecurity solutions that include assessing Maturity Models as well as VAPT services.",
    bannerbtn: "contact us",
    bannerItems: [
        require('../images/logo-1.svg').default,
        require('../images/logo-2.svg').default,
        require('../images/logo-3.svg').default,
        require('../images/logo-4.svg').default,
        require('../images/logo-5.svg').default,
        require('../images/logo-3.svg').default,
    ],
    alt: "Logos"
}

export const speedscan = {
    rowsData: [
        {
            img: require('../images/speedscan-img.jpg'),
            alt: "SpeedScan Img",
            countBlocks: [
                { countnumber: "5+", countdetail: "Cloud/Net Scan Engines" },
                { countnumber: "5+", countdetail: "Web Scan Engines" },
                { countnumber: "80+", countdetail: "Clients and Counting" },
            ],
            comingsoon: "In Alpha!",
            maintitle: "SpeedScan",
            maindetail: "Speedscan offers the capability to perform automated scans with high precision. Zero false positives guranranteed. We leverage Market leading Scanning engines and years of expertise in Manual testing to deliver top notch VAPT reports. Our custom reporting can be used to generate elegant reports that are audit ready to satisfy compliance needs such as ISO 27001:2022, SOC 2 Type1|2, etc. ",
            speedscanData: [
                { title: "Targets", detail: "Select your target from Web site/application, Network, Cloud (AWS, GCP, Azure) or Source Code reviews." },
                { title: "Scans", detail: "Scanning Engines leveraged include market leading Nessus, Qualys (PCI DSS), Acunetix, Burp Suite Pro, ZAP" },
                { title: "Risk", detail: "Track and perform Risk Management from a single view including Risk Assessment and Risk Treatment." },
                { title: "PCI ASV scans", detail: "Get your PCI DSS ASV scans at a minimal cost than market. Contact us for the Annual packages on PCI DSS ASV scans." },
            ],
            btnLink: "REQUEST QUOTE",
            btnText: "LEARN MORE",
        },
        {
            img: require('../images/vciso-img.jpg'),
            alt: "vciso-img",
            countBlocks: [
                { countnumber: "7+", countdetail: "vCISOs" },
                { countnumber: "9+", countdetail: "CISO Modules" },
            ],
            comingsoon: "Launching Q1,2025!",
            maintitle: "vCISO",
            maindetail: "Launching Q1,2025! Our Virtual Chief Information Security Officer (vCISO) platform provides businesses with automation to engage complex business critical securuty services to oversee our clients cybersecurity strategy without the need for a full-time, in-house executive. Moreover , you can outsource to our empanneled cybersecurity experts who performs the role of a CISO on a part-time, temporary, or as-needed basis. We provide Strategic Planning, Risk Management, Compliance and Governance, Security Awareness Training and many more.",
            speedscanData: [
                { title: "Trust Center", detail: "Deploy your own Trust Center as a central hub to showcase security, privacy, compliance, and trustworthiness." },
                { title: "Vendor Risk Management", detail: "Get help in identifying, assessing, monitoring, and mitigating risks associated with third-party vendors and service providers." },
                { title: "Threat Intelligence", detail: "Leverage our expertise to be on top of potential or current threats that could harm an organization." },
                { title: "Phishing Simulation", detail: "We simulate phishing emails to employees to gauge their ability to recognize and respond appropriately to phishing emails." },
            ],
            btnLink: "REQUEST QUOTE",
            btnText: "LEARN MORE",
        },
        {
            img: require('../images/cloud-security-img.jpg'),
            alt: "cloud-security-img",
            /*
            countBlocks: [
                { countnumber: "100+", countdetail: "Service Information" },
                { countnumber: "100+", countdetail: "Service Information" },
                { countnumber: "100+", countdetail: "Service Information" },
            ],
            */
            maintitle: "Cloud Security",
            maindetail: "Safeguard applications and infrastructure elements from threats, data breaches, and unauthorized access. We use industry best-practices, metrics and frameworks to help organizations assess and improve their cloud security posture including governance, risk management, compliance, and technical security measures. ",
            speedscanData: [
                { title: "SIEM", detail: "We help in collection and analysis of security data from cloud environments to detect and respond to threats." },
                { title: "DR/BCP", detail: "We help ensure availability and resilience of cloud services during and after disruptive events." },
                { title: "CSA STAR", detail: "We offer third-party consulting to obtain STAR Level 1 and 2 to showcase security posture based on the CSA Cloud Controls Matrix (CCM) and CAIQ." },
                { title: "Secure Posture", detail: "Cloud security posture assessments for open ports, Internet facing bucket access, IAM misconfigurations, Cross account access, Key Management, etc." },
            ],
            btnLink: "REQUEST QUOTE",
            btnText: "LEARN MORE",
        },
        {
            img: require('../images/compliance-img.jpg'),
            alt: "compliance-img",
            countBlocks: [
                { countnumber: "10+", countdetail: "Compliances" },
                { countnumber: "25+", countdetail: "Clients" },
            ],
            maintitle: "Compliance",
            maindetail: "Leverage industry best consulting and implementation services to adhere to regulations, standards, and guidelines relevant to information technology (IT) systems and Information Security Management System (ISMS).",
            speedscanData: [
                { title: "ISO 27001 : 2022", detail: "Get help with implementing and become certified on ISO 27001 which is internationally recognized standard for ISMS." },
                { title: "SOC 2 Type 2", detail: "Our expertise in SOC TSP for security, availability, processing integrity, confidentiality, and privacy." },
                { title: "PCI DSS", detail: "Ensure to maintain a secure environment to accept, process, store, or transmit credit card information." },
                { title: "HIPAA", detail: "Expertise to implement Privacy rule, Security rule, Breach notification rule for Covered Entities and Business Associates." },
            ],
            btnLink: "REQUEST QUOTE",
            btnText: "LEARN MORE",
        }
    ]
};

export const reviews = {
   
    maintitle: "Listen to our customers",
    reviewLogo: [
        // require('../images/review-logo-1.svg').default,
        // require('../images/review-logo-2.svg').default,
        // require('../images/review-logo-3.svg').default,
        // require('../images/review-logo-4.svg').default,
        // require('../images/review-logo-5.svg').default,
        // require('../images/clients/audi.svg').default,
        require('../images/clients/bajaj.svg').default,
        require('../images/clients/niyo.svg').default,
        require('../images/clients/kirloskar.svg').default,
        require('../images/clients/cred.svg').default,
    ],
    alt: "reviews Logo",
    reviewDetails: [
        {
            title: 'Leading Manufacturer',
            detail: 'We are part of Speedscan Alpha for 2 months now. The quality and reliability of their VAPT services have exceeded our expectations. It has helped remove vulnerabilities in our application and due to constant monitoring we have saved valuable time and resources.',
            /*img: require('../images/review-img.jpg'),*/
            name: 'Information Security Manager',
            /*position: 'Jane Doe'*/
        },
        {
            title: 'Startup in Ecommerce',
            detail: 'We have engaged with S3Infosoft for third party risk assessment and Cloud Security. The reports have provided deep insights for improving our security posture. We recommend them.',
            /*img: require('../images/review-img.jpg'),*/
            name: 'GRC Manager',
            /*position: 'Jane Doe'*/
        },
        {
            title: 'Large conglomerate',
            detail: 'The large scale of our operations pose unique challenges and we are happy to engage with you for helping with Vendor Risk due diligence services. We eagerly await the launch of your vCISO platform and wish you best for the same."',
            /*img: require('../images/review-img.jpg'),*/
            name: 'IT Risk Manager',
            /*position: 'Information Security Manager'*/
        },
        {
            title: 'Leading NBFC in India',
            detail: 'Partnering with S3Infosoft has been instrumental in our cybersecurity strategy success. Their dedication to customer service, coupled with their innovative solutions, has truly set them apart. We highly recommend their services."',
            /*img: require('../images/review-img.jpg'),*/
            name: 'Information Security Officer',
            /*position: 'Information Security Manager'*/
        },
        /*
        {
            title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eu metus risus. Suspendisse sit amet interdum mi, dapibus ultricies velit. Nunc eu pellentesque ante. Vivamus at commodo quam, a tempor elit. Ut porttitor, sem ac scelerisque elementum, sem leo semper lectus, id dictum eros ligula et mauris.',
            img: require('../images/review-img.jpg'),
            name: 'John Smith',
            position: 'Product Manager'
        },
        {
            title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eu metus risus. Suspendisse sit amet interdum mi, dapibus ultricies velit. Nunc eu pellentesque ante. Vivamus at commodo quam, a tempor elit. Ut porttitor, sem ac scelerisque elementum, sem leo semper lectus, id dictum eros ligula et mauris.',
            img: require('../images/review-img.jpg'),
            name: 'John Smith',
            position: 'Product Manager'
        },
        {
            title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eu metus risus. Suspendisse sit amet interdum mi, dapibus ultricies velit. Nunc eu pellentesque ante. Vivamus at commodo quam, a tempor elit. Ut porttitor, sem ac scelerisque elementum, sem leo semper lectus, id dictum eros ligula et mauris.',
            img: require('../images/review-img.jpg'),
            name: 'John Smith',
            position: 'Product Manager'
        },
        */
    ]
}

export const footer = {
    logo: require('../images/logo.png'),
    alt: "logo",
    footerinfotitle: "Meet Us",
    footerinfodetail: "Futura, Wework, Magarpatta Rd, Hadapsar, Pune, Maharashtra 411028, India",
    footerlinktitle1: "Quick Links",
    footerlinktitle2: "Support",
    footerlink1: "Speedscan",
    footerlink2: "vCISO",
    footerlink3: "Cloud Security",
    footerlink4: "Compliance",
    footerlink5: "Blog",
    footerlink6: "Contact Us",
    footerlink7: "Cancellation",
    footerlink8: "Support",
    footerlink9: "Privacy",
    footerlink10: "Terms",
    footerlink11: "PCI ASV Scan",
    footercopyright: "Copyright © 2018 - 2024 S3Infosoft - All rights reserved. "
}

export const contactus = {
    contactbgimg: {
        backgroundImage: `url(${require('../images/contactbg.jpg')})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    cotactsectiontitle: "Contact Us",
    contactdetailparagraph: "Every company needs support, connectivity, and security. However, the specific needs of your company are unique to you. Send us a message, and we can help you find the services that best fit your needs.",
    contactlist1: "6+ years of service",
    contactlist2: "Focus on Quality",
    contactlist3: "Lowest prices in India",
    contactformtitle: "Write to us with your requirement and our team shall get back to you promptly!",
    submitbtntext: "REQUEST QUOTE"
}